import React, { useState, useEffect } from 'react';
import { Container, Form, Input, Button, Select } from '../styles/StyledComponents';
import { getExams, getMockTests, postUploadData } from '../services/api';

const DataUploadPage = () => {
  const [uploadType, setUploadType] = useState('');
  const [formData, setFormData] = useState({});
  const [exams, setExams] = useState([]);
  const [mockTests, setMockTests] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');

  const [selectedExam, setSelectedExam] = useState('');
  const [selectedMockTest, setSelectedMockTest] = useState('');

  useEffect(() => {
    if (uploadType === 'questions' || uploadType === 'mockTest') {
      fetchExams();
    }
  }, [uploadType]);

  useEffect(() => {
    if (selectedExam) {
      fetchMockTests(selectedExam);
    }
  }, [selectedExam]);

  const fetchExams = async () => {
    const response = await getExams();
    setExams(response.data);
  };

  const fetchMockTests = async (examId) => {
    //const response = await axios.get(`/api/mock-tests/${examId}`);
    const response = await getMockTests(examId);
    setMockTests(response.data);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleFileUpload = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      if (key !== 'file' && (uploadType !== 'questions' || key !== 'testId')) {
        data.append(key, formData[key]);
      }
    }
    data.append('uploadType', uploadType);
  
    if (uploadType === 'questions') {
      data.append('file', formData.file);
      data.append('testId', selectedMockTest);
    }
  
    try {
      const response = await postUploadData(data);
      setUploadStatus(response.data.message);
    } catch (error) {
      setUploadStatus('Upload failed: ' + error.message);
    }
  };
  

  return (
    <Container>
      <h1>Upload Data</h1>
      <Form onSubmit={handleSubmit}>
        <Select onChange={(e) => setUploadType(e.target.value)}>
          <option value="">Select upload type</option>
          <option value="exam">Exam</option>
          <option value="mockTest">Mock Test</option>
          <option value="questions">Questions</option>
        </Select>

        {uploadType === 'exam' && (
          <>
            <Input name="examId" placeholder="Exam ID" onChange={handleInputChange} />
            <Input name="title" placeholder="Title" onChange={handleInputChange} />
            <Input name="description" placeholder="Description" onChange={handleInputChange} />
            <Input name="marksPerQuestion" type="number" step="1" placeholder="Marks Per Question" onChange={handleInputChange} />
            <Input name="negativeMarksPerQuestion" type="number" step="0.01" placeholder="Negative Marks Per Question" onChange={handleInputChange} />
            <Input name="totalQuestions" type="number" step="1" placeholder="Total Questions" onChange={handleInputChange} />
            <Input name="totalMarks" type="number" step="1" placeholder="Total Marks" onChange={handleInputChange} />
            <Input name="duration" type="number" placeholder="Duration (in minutes)" onChange={handleInputChange} />
            <Input name="passingScore" type="number" placeholder="Passing Score" onChange={handleInputChange} />
            <Input name="difficulty" placeholder="Difficulty" onChange={handleInputChange} />
            <Input name="topics" placeholder="Topics (comma-separated)" onChange={handleInputChange} />
          </>
        )}

        {uploadType === 'mockTest' && (
          <>
            <Select name="examId" onChange={handleInputChange}>
              <option value="">Select Exam</option>
              {exams.map(exam => (
                <option key={exam.examId} value={exam.examId}>{exam.title}</option>
              ))}
            </Select>
            <Input name="testId" placeholder="Test ID" onChange={handleInputChange} />
            <Input name="duration" type="number" placeholder="Duration (in minutes)" onChange={handleInputChange} />
            <Input name="marksPerQuestion" type="number" placeholder="Marks Per Question" onChange={handleInputChange} />
            <Input name="negativeMarksPerQuestion" type="number" step="0.01" placeholder="Negative Marks Per Question" onChange={handleInputChange} />
            <Input name="totalMarks" type="number" placeholder="Total Marks" onChange={handleInputChange} />
            <Input name="totalQuestions" type="number" placeholder="Total Questions" onChange={handleInputChange} />
          </>
        )}

        {uploadType === 'questions' && (
          <>
            <Select name="examId" onChange={(e) => setSelectedExam(e.target.value)}>
              <option value="">Select Exam</option>
              {exams.map(exam => (
                <option key={exam.examId} value={exam.examId}>{exam.title}</option>
              ))}
            </Select>
            {selectedExam && (
              <Select name="testId" onChange={(e) => setSelectedMockTest(e.target.value)}>
                <option value="">Select Mock Test</option>
                {mockTests.map(test => (
                  <option key={test.testId} value={test.testId}>{test.testId}</option>
                ))}
              </Select>
            )}
            {selectedMockTest && (
              <Input type="file" accept=".json" onChange={handleFileUpload} />
            )}
          </>
        )}

        <Button type="submit">Upload</Button>
      </Form>
      {uploadStatus && <p>{uploadStatus}</p>}
    </Container>
  );
};

export default DataUploadPage;
