import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About MockTestPro</h1>
      <p>Welcome to MockTestPro, your number one source for all mock tests. We're dedicated to providing you the best of test preparation, with a focus on dependability, customer service, and uniqueness.</p>
      <p>Founded in 2023, MockTestPro has come a long way from its beginnings. When we first started out, our passion for helping students drove us to start our own business.</p>
      <p>We hope you enjoy our products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us.</p>
      <p>Sincerely,<br />The MockTestPro Team</p>
    </div>
  );
};

export default About;
