import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import './styles/NewStyles.css';
import App from './App';
import HomePage from './pages/HomePage';
import ExamPage from './pages/ExamPage';
import MockTestPage from './pages/MockTestPage';
import ResultsPage from './pages/ResultsPage';
import reportWebVitals from './reportWebVitals';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import DataUploadPage from './pages/DataUploadPage';
import ComingSoon from './components/ComingSoon';
// import DmvHomePage from './pages/DMV/HomePage';
// import SscHomePage from './pages/SSC/HomePage';
// import RrbHomePage from './pages/RRB/HomePage';
// import IbpsHomePage from './pages/IBPS/HomePage';
// import SSCCGLTestSelector from './pages/SSC/SSCCGLTestSelectorPage';
import BlogPage from './Blog/pages/BlogPage';

import About from './pages/About';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'exam/:examId', element: <ExamPage /> },
      { path: 'mocktest/:testId', element: <MockTestPage /> },
      { path: 'results/:testId', element: <ResultsPage /> },
      { path: 'login', element: <LoginPage /> },
      { path: 'signup', element: <SignupPage /> },
      { path: 'dataupload', element: <DataUploadPage /> },
      // { path: 'dmv', element: <DmvHomePage /> },
      { path: 'comingsoon', element: <ComingSoon /> },
      // { path: 'ssc', element: <SscHomePage /> },
      // { path: 'ssc-cgl', element: <SSCCGLTestSelector /> },
      // { path: 'ibps', element: <IbpsHomePage /> },
      // { path: 'rrb', element: <RrbHomePage /> },
      // { path: 'aptitude', element: <ComingSoon /> },
      // { path: 'reasoning', element: <ComingSoon /> },
      // { path: 'engineering', element: <ComingSoon /> },
      // { path: 'gre', element: <ComingSoon /> },
      {path: 'about', element: <About /> },
      {path: 'terms', element: <Terms /> },
      {path: 'privacy', element: <Privacy /> },
      {path: 'blog', element: <BlogPage /> },


    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
