import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('/images/bg-image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
`;

const HomeLink = styled(Link)`
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #155db1;
  }
`;

const ComingSoon = () => {
  return (
    <ComingSoonContainer>
      <Title>Coming Soon!</Title>
      <Message>We're working hard to bring you something amazing. Stay tuned!</Message>
      <HomeLink to="/">Back to Home</HomeLink>
    </ComingSoonContainer>
  );
};

export default ComingSoon;
