import React, { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { GlobalStyle } from './styles/StyledComponents';
import Navigation from './components/Navigation';

const HomePage = lazy(() => import('./pages/HomePage'));
const ExamPage = lazy(() => import('./pages/ExamPage'));
const MockTestPage = lazy(() => import('./pages/MockTestPage'));
const ResultsPage = lazy(() => import('./pages/ResultsPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const SignupPage = lazy(() => import('./pages/SignupPage'));
const DataUploadPage = lazy(() => import('./pages/DataUploadPage'));
// const ProfilePage = lazy(() => import('./pages/ProfilePage'));

function App() {
  return (
    <>
      <GlobalStyle />
      <Navigation />
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
    </>
  );
}

export default App;
