import React, { useState, useEffect } from 'react';
import BlogTemplate from '../../components/BlogTemplate';
import testBlogContent from './testBlog.html';

const BlogPage = () => {
    const [posts, setPosts] = useState([]);
  
    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(testBlogContent, 'text/html');
        const title = doc.querySelector('h1').textContent;
        doc.querySelector('h1').remove();
        const content = doc.body.innerHTML;
        
        const wordCount = content.split(/\s+/).length;
        const readingTime = Math.ceil(wordCount / 200); // Assuming 200 words per minute
      
        setPosts([{ id: 1, title, content, wordCount, readingTime }]);
      }, []);
      
      
  
    return (
      <div>
        {posts.map(post => (
          <BlogTemplate key={post.id} title={post.title} content={post.content} wordCount={post.wordCount} readingTime={post.readingTime} />
        ))}
      </div>
    );
  };

export default BlogPage;