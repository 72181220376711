import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const titleSets = {
  '/': [
    "Welcome to Your Exam Prep Hub!",
    "Boost Your Exam Preparation!",
    "Ace Your Exams with Confidence!",
    "Discover Top Mock Tests for Every Exam!",
    "Prepare Smarter, Not Harder!",
    "Explore Our Latest Mock Tests!"
  ],
  '/dmv': [
    "Master Your DMV Test with Confidence!",
    "Get Ready for Your Driver's License Exam!",
    "Practice DMV Tests for All 50 States!",
    "Ace Your DMV Written Test Today!",
    "Prepare for Your DMV Exam Like a Pro!",
  ],
  // Add more paths and title sets as needed
};

const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const AnimatedTitle = styled.h1`
  color: #3498db;
  font-size: 2em;
  text-align: center;
  margin-bottom: 10px;
  padding-bottom: 20px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(52, 152, 219, 0.1);
  animation: ${fadeIn} 1s ease-in-out;
`;

const Subtitle = styled.p`
  color: #34495e;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 30px;
`;

const RotatingTitle = () => {
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const location = useLocation();
  const currentPath = location.pathname;
  const titles = titleSets[currentPath] || titleSets['/'];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [titles]);

  return (
    <header>
      <Helmet>
        <title>{titles[currentTitleIndex]} | MockTestPro</title>
        <meta name="description" content={`Prepare for your exams with MockTestPro. ${titles[currentTitleIndex]}`} />
      </Helmet>
      <AnimatedTitle aria-live="polite">{titles[currentTitleIndex]}</AnimatedTitle>
      <Subtitle>Explore our wide range of mock tests to ace your exams with confidence.</Subtitle>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "WebSite",
          "name": "MockTestPro",
          "url": `https://mocktestpro.com${currentPath}`,
          "description": `${titles[currentTitleIndex]} - Prepare for your exams with MockTestPro.`,
          "potentialAction": {
            "@type": "SearchAction",
            "target": `https://mocktestpro.com${currentPath}/search?q={search_term_string}`,
            "query-input": "required name=search_term_string"
          }
        })}
      </script>
    </header>
  );
};

export default RotatingTitle;
