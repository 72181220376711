import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Copyright from './Copyright';

const FooterWrapper = styled.footer`
  background-color: var(--footer-bg-color, #34495e);
  color: var(--footer-text-color, #ecf0f1);
  padding: 60px 0 20px;
  margin-top: 60px;
`;

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  margin: 0 20px 40px;
  min-width: 200px;

  @media (min-width: 769px) {
    flex: 0 1 30%;
  }

  h3 {
    font-size: 1.4em;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 10px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50px;
      height: 2px;
      background-color: var(--primary-color, #3498db);
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 12px;

      a, i {
        color: var(--footer-text-color, #ecf0f1);
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: var(--primary-color, #3498db);
        }
      }

      i {
        margin-right: 10px;
        color: var(--primary-color, #3498db);
      }
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;

  a {
    color: var(--footer-text-color, #ecf0f1);
    font-size: 1.5em;
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary-color, #3498db);
    }
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  margin-top: 15px;

  @media (max-width: 480px) {
    flex-direction: column;
  }

  input[type="email"] {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 4px 0 0 4px;

    @media (max-width: 480px) {
      border-radius: 4px;
      margin-bottom: 10px;
    }
  }

  button {
    padding: 10px 15px;
    background-color: var(--primary-color, #3498db);
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;

    @media (max-width: 480px) {
      border-radius: 4px;
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSection>
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/about">About Us</a></li>
            {/* <li><a href="/faq">FAQ</a></li> */}
            <li><a href="/privacy">Privacy Policy</a></li>
            <li><a href="/terms">Terms of Service</a></li>
            {/* <li><a href="/blog">Blog</a></li> */}
            <li><a href="/sitemap">Sitemap</a></li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>Contact Us</h3>
          <ul>
            <li><i className="fas fa-envelope"></i> support@mocktestpro.com</li>
          </ul>
          <SocialIcons>
            <a href="https://facebook.com/dmvmocktests" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
            <a href="https://twitter.com/dmvmocktests" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="https://instagram.com/dmvmocktests" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
            <a href="https://youtube.com/dmvmocktests" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
          </SocialIcons>
        </FooterSection>
        <FooterSection>
          <h3>Stay Updated</h3>
          <p>Subscribe to our newsletter for the latest mock test updates and tips.</p>
          <NewsletterForm>
            <input type="email" placeholder="Enter your email" required />
            <button type="submit">Subscribe</button>
          </NewsletterForm>

        </FooterSection>
      </FooterContent>
      <Copyright />
    </FooterWrapper>
  );
};

export default Footer;
