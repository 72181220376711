import React from 'react';

const Privacy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>Your privacy is important to us. It is MockTestPro's policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://mocktestpro.com">https://mocktestpro.com</a>, and other sites we own and operate.</p>
      <h2>Information We Collect</h2>
      <p>We only collect information about you if we have a reason to do so, for example, to provide our services, to communicate with you, or to make our services better.</p>
      <h2>How We Use Information</h2>
      <p>We use the information we collect in various ways, including to provide, operate, and maintain our website, improve, personalize, and expand our website, understand and analyze how you use our website, develop new products, services, features, and functionality, and communicate with you.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions about our privacy policy, please contact us at support@mocktestpro.com.</p>
    </div>
  );
};

export default Privacy;
