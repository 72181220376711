// src/pages/HomePage.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getExams, getTopExams, postSearchKeyword } from '../services/api';
import { Container, Title, Input, List, ListItem, NoResultsMessage } from '../styles/StyledComponents';
import { SquareCard, CardImage, CardTitle, MovingCardsContainer, MovingCardsInner } from '../styles/StyledComponents';
import RotatingTitle from '../components/RotatingTitle';
import Footer from '../components/Footer';

const HomePage = () => {
  const [exams, setExams] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [topExams, setTopExams] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      fetchExams();
      fetchTopExams();
    }
  }, []);
  const [isLoading, setIsLoading] = useState(true);

  const fetchExams = async () => {
    setIsLoading(true);
    try {
      const response = await getExams();
      setExams(response.data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTopExams = async () => {
    try {
      const response = await getTopExams(); // Assuming you have this API function
      setTopExams(response.data);
    } catch (error) {
      console.error('Error fetching top exams:', error);
      // Optionally set some default data if the fetch fails
      setTopExams([
      ]);
    }
  };

  const filteredExams = exams.filter(exam =>
    exam.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const duplicatedExams = [...exams, ...exams];

  //This call is to capture the search term from the frontend so that we can add mostly requested exams and implement them.
  const triggerBackendSearch = async (term) => {
    setHasSearched(true);
    try {
      const response = await postSearchKeyword({ keyword: term });
      // const response = await axios.post('/api/exams/search', { keyword: term });
      console.log(`Search for "${term}" recorded. Hit count: ${response.data.hitCount}`);
    } catch (error) {
      console.error('Error recording search:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (hasSearched) {
      setHasSearched(false);
    }
  };

  return (
    <div style={{
      backgroundImage: `url(/images/bg-image.jpg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      minHeight: '100vh',
       padding: '20px'
    }}>
    <Container>
      <RotatingTitle />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '800px', margin: '0 auto' }}>
      <Input
        type="text"
        placeholder="Search exams..."
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ borderColor: '#3498db', color: '#2c3e50' }}
      />

      <div style={{ height: '200px', overflowY: 'auto' }}>
        {searchTerm ? (
          filteredExams.length > 0 ? (
            <List>
              {filteredExams.map((exam) => (
                <ListItem key={exam.examId}>
                  <Link to={`/exam/${exam.examId}`} style={{ color: '#3498db', textDecoration: 'none' }}>{exam.title}</Link>
                </ListItem>
              ))}
            </List>
          ) : (
            hasSearched ? (
              <NoResultsMessage style={{ color: '#7f8c8d' }}>
                <p>We couldn't find any exams matching your search.</p>
                <p>Don't worry! We're constantly working on adding new exams to our collection.</p>
                <p>Please check back soon or try a different search term.</p>
              </NoResultsMessage>
            ) : (
              <List>
                <ListItem onClick={() => triggerBackendSearch(searchTerm)}>
                  <span style={{ color: '#3498db', cursor: 'pointer' }}>
                    Explore all available exams for "{searchTerm}" - Click to expand your search
                  </span>
                </ListItem>
              </List>
            )
          )
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '800px', margin: '0 auto' }}>
            <h3 style={{ color: '#3498db', marginBottom: '10px' }}>Most Popular Exams This Month</h3>
            <List>
              {topExams.map((exam) => (
                <ListItem key={exam.examId}>
                  <Link to={`/exam/${exam.examId}`} style={{ color: '#3498db', textDecoration: 'none' }}>{exam.title}</Link>
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </div>
      </div>

      <MovingCardsContainer>
        <MovingCardsInner>
          {duplicatedExams.map((exam, index) => (
            <Link to={`/exam/${exam.examId}`} key={`${exam.examId}-${index}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <SquareCard style={{ backgroundColor: '#ecf0f1', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
                <CardImage src={exam.imageUrl || '/images/mockTest256x256.png'} alt={exam.title} />
                <CardTitle style={{ color: '#2c3e50' }}>{exam.title}</CardTitle>
              </SquareCard>
            </Link>
          ))}
        </MovingCardsInner>
      </MovingCardsContainer>
      <div style={{ flex: 1 }}></div>
      <Footer />
    </Container>
    </div>
  );
};

export default HomePage;