import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import { getFAQs } from '../services/api';

const FAQContainer = styled.section`
  margin: 20px 0;
`;

const FAQItem = styled.details`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const FAQQuestion = styled.summary`
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: #0066cc;
  }
`;

const FAQAnswer = styled.p`
  margin-top: 10px;
  padding-left: 20px;
`;

const FAQSection = ({ faqs }) => {
  return (
    <FAQContainer>
      <h2>Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <FAQItem key={index}>
          <FAQQuestion>{faq.question}</FAQQuestion>
          <FAQAnswer>{faq.answer}</FAQAnswer>
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQSection;