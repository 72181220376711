// src/components/BlogTemplate.js
import React from 'react';
import './../BlogTemplate.css'; // Import your CSS file
import Footer from './Footer';
import { FaClock, FaFileWord } from 'react-icons/fa';

const BlogTemplate = ({ id, title, content, wordCount, readingTime }) => {
  return (
    <div className="blog-container">
      <header className="blog-header">
        <h1>{title}</h1>
        <div className="blog-meta">
          <span><FaClock /> {readingTime} min read</span>
          <span><FaFileWord /> {wordCount} words</span>
        </div>
      </header>
      <main className="blog-content">
        <div className="blog-post" dangerouslySetInnerHTML={{ __html: content }} />
      </main>
      <Footer />
    </div>
  );
};

export default BlogTemplate;
