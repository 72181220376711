import styled from 'styled-components';
import { Link } from 'react-router-dom';

// General styles
export const Body = styled.div`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Heading = styled.h1`
    background-color: #3498db;
    color: white;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 1.0rem;
    font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1.0rem;
    padding: 0.3rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 0.1rem;
  }

`;

export const SubTitle = styled.h2`
  font-size: 1.8rem;
  color: #34495e;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

// Info box styles
export const InfoBox = styled.div`
  background-color: #f0f8ff;
  border: 1px solid #b0d4ff;
  border-left: 5px solid #2196F3;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;

  ul {
    padding-left: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 12px;
    ul {
      padding-left: 15px;
    }
  }
`;

// Test grid styles
export const TestGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 15px;
  }
`;

export const TestLink = styled(Link)`
  display: block;
  padding: 15px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease;
  font-size: 1rem;

  &:hover {
    background-color: #2980b9;
  }

  @media (max-width: 768px) {
    padding: 12px;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 0.85rem;
  }
`;

export const QuestionCount = styled.div`
  font-size: 0.9em;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }

  @media (max-width: 480px) {
    font-size: 0.75em;
  }
`;

// Section styles
export const Section = styled.section`
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 25px;
  }

  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

// Resource link styles
export const ResourceLink = styled(Link)`
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 10px 18px;
  background-color: #e74c3c;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    margin-right: 12px;
  }

  @media (max-width: 480px) {
    padding: 7px 12px;
    margin-right: 10px;
  }
`;

// Loader styles (for when data is being fetched)
export const Loader = styled.div`
  font-size: 1.5rem;
  color: #3498db;
  text-align: center;
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

// Button styles
export const Button = styled.button`
  background-color: ${({ secondary }) => (secondary ? '#7f8c8d' : '#3498db')};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ secondary }) => (secondary ? '#5d6d72' : '#2980b9')};
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 7px 12px;
    font-size: 0.85rem;
  }
`;
