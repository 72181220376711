import React from 'react';
import styled from 'styled-components';
import { FaCopyright } from 'react-icons/fa';

const CopyrightText = styled.div`
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  a {
    color: var(--footer-text-color, #ecf0f1);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary-color, #3498db);
    }
  }
`;

const Copyright = () => (
    <CopyrightText>
      <FaCopyright style={{ marginRight: '0.5rem' }} />
      {new Date().getFullYear()} MockTestPro. All rights reserved. | <a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms of Service</a>
    </CopyrightText>
);

export default Copyright;
