import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Menu, X } from 'lucide-react';

const Nav = styled.nav`
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1rem;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const NavBrand = styled(Link)`
  color: #3498db;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: white;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    align-items: center;
  }
`;

const NavButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3a7bc8;
  }

  &.outlineButton {
    background-color: transparent;
    border: 1px solid #4a90e2;
    color: #4a90e2;
    margin-right: 0.5rem;

    &:hover {
      background-color: #4a90e2;
      color: white;
    }
  }

  @media (max-width: 768px) {
    width: auto;
    min-width: 120px;
    margin-bottom: 0.5rem;
  }

  ${props => props.active && `
    background-color: #3a7bc8;
    color: white;
  `}
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Navigation = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('token');
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  return (
    <Nav>
      <NavContainer>
        <NavBrand to="/">MockTest Pro</NavBrand>
        <MenuButton onClick={toggleMenu}>
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </MenuButton>
        <NavLinks $isOpen={isOpen}>
          {/* <NavButton
            className={isActive('/') ? '' : 'outlineButton'}
            onClick={() => handleNavigation('/')}
          >
            Home
          </NavButton> */}

          {isAuthenticated ? (
            <>
              <NavButton
                className={isActive('/profile') ? '' : 'outlineButton'}
                onClick={() => handleNavigation('/profile')}
              >
                Profile
              </NavButton>
              <NavButton onClick={handleLogout}>Logout</NavButton>
            </>
          ) : (
            <>
              <NavButton
                className={isActive('/login') ? '' : 'outlineButton'}
                onClick={() => handleNavigation('/login')}
              >
                Log In
              </NavButton>
              <NavButton
                className={isActive('/signup') ? '' : 'outlineButton'}
                onClick={() => handleNavigation('/signup')}
              >
                Sign Up
              </NavButton>
            </>
          )}
        </NavLinks>
      </NavContainer>
    </Nav>
  );
};

export default Navigation;
