import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BreadcrumbContainer = styled.nav`
  padding: 10px 0;
  margin-bottom: 20px;
`;

const BreadcrumbList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

const BreadcrumbItem = styled.li`
  &:not(:last-child)::after {
    content: ">";
    margin: 0 10px;
    color: #666;
  }
`;

const BreadcrumbLink = styled(Link)`
  color: #0066cc;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Breadcrumb = ({ items }) => (
  <BreadcrumbContainer aria-label="Breadcrumb">
    <BreadcrumbList>
      {items.map((item, index) => (
        <BreadcrumbItem key={index}>
          {index === items.length - 1 ? (
            <span aria-current="page">{item.label}</span>
          ) : (
            <BreadcrumbLink to={item.url}>{item.label}</BreadcrumbLink>
          )}
        </BreadcrumbItem>
      ))}
    </BreadcrumbList>
  </BreadcrumbContainer>
);

export default Breadcrumb;