// services/api.js
import axios from 'axios';
import crypto from 'crypto-js';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_SECRET = process.env.REACT_APP_API_SECRET;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': API_KEY,
  },
});

api.interceptors.request.use((config) => {
  const timestamp = Date.now().toString();
  const method = config.method.toUpperCase();
  const path = config.url.split('?')[0]; // Remove query parameters if any
  const signature = crypto.HmacSHA256(`${method}${path}${timestamp}`, API_SECRET).toString();

  config.headers['X-Timestamp'] = timestamp;
  config.headers['X-Signature'] = signature;

  return config;
});

export const login = (credentials) => api.post('/api/auth/login', credentials);
export const signup = (userData) => api.post('/api/auth/signup', userData);
export const getExam = (examId) => api.get(`/api/exams/exam/${examId}`);
export const getExams = () => api.get('/api/exams/allexams');
export const getTopExams = () => api.get('/api/exams/topexams');
export const getMockTests = (examId) => api.get(`/api/mocktests/${examId}`);
export const getMockTestQuestions = (testId) => api.get(`/api/mocktests/${testId}/questions`);
export const submitMockTest = (testId, answers) => api.post(`/api/mocktests/${testId}/submit`, { answers });
export const postSearchKeyword = (keyword) => api.post('/api/exams/search', keyword);
export const postUploadData = (data) => api.post('/api/upload', data, {
  headers: {
    ...api.defaults.headers,
    'Content-Type':'multipart/form-data',
  },
});