import React from 'react';

import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

const escapeMarkdown = (text) => {
    // Escape special Markdown characters
    return text.replace(/([#*_`~+\-=|\\{}\[\]])/g, '\\$1');
  };
  
  export const renderWithLatex = (text) => {
    // Replace curly quotes with straight quotes
    const sanitizedText = text.replace(/['']/g, "'").replace(/[""]/g, '"');
  
    // Split the text into parts, preserving LaTeX delimiters and line breaks
    const parts = sanitizedText.split(/(\$\$.*?\$\$|\$.*?\$|'.*?')/g);
  
    return parts.map((part, index) => {
      if (part.startsWith('$$') && part.endsWith('$$')) {
        // Block math (displayed equation)
        return <InlineMath key={index} math={part.slice(2, -2)} />;
      } else if (part.startsWith('$') && part.endsWith('$')) {
        // Inline math
        return <InlineMath key={index} math={part.slice(1, -1)} />;
      } else if (part.startsWith("'") && part.endsWith("'")) {
        // Handle single-quoted parts (like 'E + B')
        const innerParts = part.slice(1, -1).split(/(\$\$.*?\$\$|\$.*?\$)/g);
        return (
          <span key={index} style={{ whiteSpace: 'nowrap' }}>
            '{innerParts.map((innerPart, innerIndex) =>
              innerPart.startsWith('$') ?
                <InlineMath key={`${index}-${innerIndex}`} math={innerPart.slice(1, -1)} /> :
                innerPart
            )}'
          </span>
        );
      } else {
        // Regular text: preserve line breaks
        return part.split('\n').map((line, lineIndex) => (
          <React.Fragment key={`${index}-${lineIndex}`}>
            {lineIndex > 0 && <br />}
            {line}
          </React.Fragment>
        ));
      }
    });
  };